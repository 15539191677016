<template>
	<div>
		<div class="source" v-if="flag">
		  {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
		  <router-link :to="{ path: '/supervise_index' }"> {{ $t('index.index') }} </router-link>
		  <span v-if="source != ''">
		    &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
		    {{ source }}
		  </span>
		</div>
		<div class="source" v-if="!flag">
		  {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
		  <router-link :to="{ path: '/business_development_index' }"> {{ $t('index.index') }} </router-link>
		  <span>
		    &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
		    {{ source }}
		  </span>
		</div>
	</div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    source: {
      type: String,
      default: "",
    },
		isSource: {
			type: String,
			default: "",
		},
  },
  watch: {},
  data() {
    return {
		flag: true
	};
  },
  activated() {
    this.flag = this.isSource === 'true'
  },
  methods: {},
};
</script>

<style scoped>
.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.source a {
  color: #fff;
}
</style>
