<template>
  <div class="container" style="margin: 0 10px">
    <Source :source="$t('supervise.involvingRightsDetail.sourceTitle')" />

    <Box :title="$t('supervise.involvingRightsDetail.personInfo')">
      <div style="width: 100%">
        <van-cell-group :border="false">
          <van-cell :title="$t('supervise.involvingRightsDetail.name')" :value="person.name" />
          <van-cell :title="$t('supervise.involvingRightsDetail.post')" :value="person.post" />
          <van-cell :title="$t('casually.feedback')">
            <div slot="default">
              <van-button plain type="danger" size="mini"
                @click="bindTousu(person.id)"
              >
                {{ $t('supervise.involvingRightsDetail.complaints') }}
              </van-button>
            </div>
          </van-cell>
        </van-cell-group>
      </div>
    </Box>

    <Box :title="$t('supervise.involvingRightsDetail.relativesInfo')">
      <div style="width: 100%">
        <template>
          <van-cell-group :border="false" v-for="item in detail" :key="item.id">
            <van-cell :title="$t('supervise.involvingRightsDetail.name')" :border="false" :value="item.name" />
            <van-cell :title="$t('supervise.involvingRightsDetail.relationship')" :border="false" :value="item.relationship" />
            <van-cell :title="$t('casually.feedback')">
              <div slot="default">
                <van-button plain type="danger" size="mini"
                  @click="bindTousu(item.id)"
                >
                  {{ $t('supervise.involvingRightsDetail.complaints') }}
                </van-button>
              </div>
            </van-cell>
          </van-cell-group>
        </template>
      </div>
    </Box>
    <!-- 底部内容 -->
    <Footer />

    <Tousu
      :moneyId="moneyId"
      :departId="departId"
      :remark="remark"
      :addsan="addsan"
    />
  </div>
</template>

<script>
import BASE from "../../../utils/base";
import Source from "../components/Source";
import Box from "../components/Box";
import Footer from "@/components/Footer";
import Tousu from "../components/Tousu";
export default {
  name: "IndexInvolvingRightsDetail",
  components: { Source, Box, Footer, Tousu },
  activated() {
    this.id = this.$route.query.id;

    const info = sessionStorage.getItem("InvolvingRightsInfo");

    this.person = JSON.parse(info);

    this.onLoad();
  },
  data() {
    return {
      // 投诉弹窗控制
      moneyId: "",
      remark: "",
      addsan: true,
      // 地址参数
      id: "",
      // 本人信息
      person: {},
      // 亲属信息
      detail: [],
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    onLoad() {
      // 请求参数
      const params = {
        pageNo: 1,
        pageSize: 10,
        pid: this.id,
      };

      this.axios
        .get(BASE.genUrl("zxSupervisePowerRelatives/childList", params))
        .then((res) => {
          let data = res.data;

          if (data.result) {
            this.detail = data.result.records;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    bindTousu(id) {
      this.moneyId = id;
      this.$modal.show("modal");
    },
  },
};
</script>

<style scoped>
.box {
}
.van-cell-group {
  border-bottom: 1px solid #ebedf0 !important;
}
</style>
