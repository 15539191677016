<template>
  <div class="news">
	  <block v-if="titleType">
		  <div class="news-bg">{{ title }}</div>
		  <div class="list">
		    <slot />
		    <slot name="right-icon" />
		  </div>
	  </block>
	  <block v-else>
		  <div class="news-bg" style="background-size: 80%;margin-bottom: 10px;">
		  	<div style="font-size: 15px;width: 200px;">{{ title }}</div>
		  </div>
		  <div class="list" style="margin-top: 25px;">
		    <slot />
		    <slot name="right-icon" />
		  </div>
	  </block>
  </div>
</template>

<script>
export default {
  name: "box",
  props: {
    title: {
      type: String,
      default: "标题",
    },
	titleType: {
		type: Boolean,
		default: true
	}
  },
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}
.news-bg {
  background: url("../../../../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}
.list {
  text-align: left;
  /* padding: 0.8rem; */
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
}
.list {
  display: flex;
  justify-content: space-between;
}
</style>
