<template>
  <div class="footer">
    <slot></slot>
    <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
    <!-- <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p> -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  watch: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}
</style>
